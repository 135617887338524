import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components-pl/layout";

import Seo from "../../components-pl/seo";
import Revs from "../../components-pl/reviews";
import SliderDrinks from "../../components-pl/slider-drinks";
import BlogModule from "../../components-pl/post-module";
import { useEffect } from "react";

const Home = ({ data, location }) => {
  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY >= 45) {
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.top = "0px";
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.position = "fixed";
        document.querySelector("main").style.marginTop = "45px";
      } else {
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.top = "0px";
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.position = "relative";
        document.querySelector("main").style.marginTop = "0px";
      }
      let mybtn = document.getElementById("back-to-top");
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        mybtn.style.display = "block";
      } else {
        mybtn.style.display = "none";
      }
    };
  });
  function b2b1() {
    return (
      <StaticImage
        src="../../images/b1.jpg"
        alt="shroom dla gastronomii"
        placeholder="blurred"
        className="b2bimg"
        layout="constrained"
        height="875"
        width="500"
        style={{
          boxShadow: "5px 5px 20px rgba(23,19,43,.2)",
          borderRadius: "25px",
        }}
        formats={["auto", "webp", "avif"]}
      />
    );
  }
  function b2b2() {
    return (
      <StaticImage
        src="../../images/b2.jpg"
        alt="shroom dla dystrybutorów"
        className="b2bimg"
        placeholder="blurred"
        layout="constrained"
        height="875"
        width="500"
        style={{
          boxShadow: "5px 5px 20px rgba(23,19,43,.2)",
          borderRadius: "25px",
        }}
        formats={["auto", "webp", "avif"]}
      />
    );
  }
  function b2b3() {
    return (
      <StaticImage
        src="../../images/b3.jpg"
        alt="shroom dla biur"
        className="b2bimg"
        placeholder="blurred"
        layout="constrained"
        height="875"
        width="500"
        style={{
          boxShadow: "5px 5px 20px rgba(23,19,43,.2)",
          borderRadius: "25px",
        }}
        formats={["auto", "webp", "avif"]}
      />
    );
  }

  // const siteTitle = data.site.siteMetadata.title;

  return (
    <>
      <Layout data={data} location={location}>
        <Seo
          title="Shroom - naturalny napój energetyzujący z kordycepsem, soplówką jeżowatą i l'teaniną"
          description="Soplówka jeżowata, cordyceps i L-teanina to naturalne superfoods wspierające funkcje mózgu, podnoszące odporność i redukujące stres. W naszej ofercie znajdziesz szeroki wybór produktów: od naturalnych napojów energetycznych po odzież i akcesoria. Odkryj naszą gamę i zyskaj na zdrowiu i samopoczuciu."
        />
        {/* Hero */}
        <div
          id="hwrap"
          className="container-fluid mush"
          style={{
            padding: "0",
            top: "0",
            minHeight: "720px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            id="hero"
            className="mask container-fluid"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100%",
              minWidth: "100%",
              height: "100%",
              minHeight: "800px",
              justifyContent: "center",
            }}
          >
            <div
              className="col-8"
              style={{
                minHeight: "400px",
                display: "block",
                textAlign: "center",
              }}
            >
              <h1
                className="text-white mob-hero spec-h"
                style={{
                  textAlign: "center",
                  fontSize: "5em",
                  wordBreak: "break-word",
                }}
              >
                shroom drink
              </h1>
              <br />
              <h2
                className="text-white"
                style={{
                  textAlign: "center",
                  fontSize: "1.5em",
                  fontWeight: "800",
                  letterSpacing: "2px",
                }}
              >
                Holistycznie zadbaj o ciało
                <br />i umysł z napojem wellness z adaptogenami
              </h2>
              <p
                className="text-white"
                style={{
                  textAlign: "center",
                  margin: "auto",
                  maxWidth: "500px",
                  color: "#111",
                }}
              >
                <br />
                <br />
                <a
                  href="#mushroom-drinks"
                  className="btn btn-outline-light"
                  style={{ margin: "10px" }}
                >
                  więcej&nbsp;
                  <span role="img" aria-label="mushroom">
                    🍄
                  </span>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://shop.shroom4you.com/pl/"
                  className="btn btn-outline-light"
                  style={{ margin: "10px" }}
                >
                  zobacz sklep&nbsp;
                  <span role="img" aria-label="shop">
                    🛒
                  </span>
                </a>
              </p>
              <br />
              <div
                style={{
                  padding: "2em",
                  textAlign: "center",
                  color: "white",
                }}
              >
                <a
                  id="scroll-to"
                  href="#mushroom-drinks"
                  style={{ fontSize: "15px" }}
                >
                  ⇩
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          id="mushroom-drinks"
          style={{
            width: "100%",
            padding: "0",
            marginTop: "-10px",
            backgroundColor: "#E8D3D0",
          }}
        >
          <br />
          <div className="row container-fluid" style={{ margin: "auto" }}>
            <div
              className="column col mx-auto"
              style={{ height: "750px", position: "relative" }}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://shop.shroom4you.com/pl/"
              >
                <div
                  className="home-mod"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    padding: "1em",
                    margin: "1em",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "20px",
                      left: "auto",
                      padding: "1em",
                      color: "#1D1D1D",
                      zIndex: 2,
                      maxWidth: "362px",
                    }}
                  >
                    <h2 className="spec-h home-m">shroom shop</h2>
                    <p>
                      Prawdopodobnie{" "}
                      <span className="spec-h">#1 grzybowy napój</span>{" "}
                      funkcjonalny w Europie.
                    </p>
                    <br />
                    <p style={{ textAlign: "center", maxWidth: "100%" }}>
                      <button className="btn">
                        zobacz sklep&nbsp;
                        <span role="img" aria-label="shopping">
                          🛒
                        </span>
                      </button>
                    </p>
                  </div>
                  <video
                    style={{
                      height: "650px",
                      boxShadow: "5px 5px 20px rgba(23,19,43,.2)",
                      borderRadius: "25px",
                      margin: "auto",
                      maxWidth: "100%",
                      objectFit: "cover",
                    }}
                    autoPlay="autoplay"
                    loop="loop"
                    muted
                    defaultmuted="true"
                    playsInline
                    onContextMenu={() => {
                      return false;
                    }}
                    loading="lazy"
                    src="https://admin.shroom4you.com/wp-content/uploads/2024/01/ANIMACJA_11.mp4"
                  ></video>
                </div>
              </a>
            </div>
            <div
              className="column col mx-auto mod-r"
              style={{ height: "750px" }}
            >
              <a href="#b2b">
                <div
                  className="home-mod"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    padding: "1em",
                    margin: "1em",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "20px",
                      left: "auto",
                      padding: "1em",
                      color: "#FEFEFE",
                      zIndex: 2,
                      width: "100%",
                    }}
                  >
                    <h2 className="spec-h home-m" style={{ color: "white" }}>
                      shroom dla B2B
                    </h2>
                    <p>
                      Zapoznaj się z naszą ofertą B2B{" "}
                      <span className="spec-h">shroom drinks</span>.
                    </p>
                    <br />
                    <p style={{ textAlign: "center" }}>
                      <button className="btn">
                        dowiedz się więcej&nbsp;
                        <span role="img" aria-label="b2b">
                          ✨
                        </span>
                      </button>
                    </p>
                  </div>

                  <img
                    src="https://admin.shroom4you.com/wp-content/uploads/2024/01/shroomscriptions.jpg"
                    alt="oferta b2b shroom drinks"
                    loading="lazy"
                    className="home-mod-img"
                    style={{
                      gridArea: "1/1",
                      width: "100%",
                      objectFit: "cover",
                      minHeight: "300px",
                      maxWidth: "100%",
                      boxShadow: "5px 5px 20px rgba(23,19,43,.2)",
                      borderRadius: "25px",
                    }}
                  />
                </div>
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://shop.shroom4you.com/pl/kontakt/"
              >
                <div
                  className="home-mod"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    padding: "1em",
                    margin: "1em",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "20px",
                      left: "auto",
                      padding: "1em",
                      color: "#FEFEFE",
                      zIndex: 2,
                      width: "100%",
                    }}
                  >
                    <h2 className="spec-h home-m" style={{ color: "white" }}>
                      Kontakt
                    </h2>
                    <p>
                      Porozmawiajmy! Skontakuj się ze{" "}
                      <span className="spec-h">shroom</span>!
                    </p>
                    <br />
                    <p style={{ textAlign: "center" }}>
                      <button className="btn">
                        napisz do nas&nbsp;
                        <span role="img" aria-label="mushroom">
                          🍄
                        </span>
                      </button>
                    </p>
                  </div>

                  <img
                    src="https://admin.shroom4you.com/wp-content/uploads/2024/01/acc.jpg"
                    alt="skontaktuj się ze shroom"
                    loading="lazy"
                    className="home-mod-img"
                    style={{
                      gridArea: "1/1",
                      width: "100%",
                      objectFit: "cover",
                      minHeight: "300px",
                      maxWidth: "100%",
                      boxShadow: "5px 5px 20px rgba(23,19,43,.2)",
                      borderRadius: "25px",
                    }}
                  />
                </div>
              </a>

              <div style={{ marginTop: "30px" }}> </div>
            </div>
          </div>
        </div>

        <div
          id="b2b"
          style={{
            width: "100%",
            padding: "2rem",
            paddingTop: "50px",
            marginBottom: "50px",
            backgroundColor: "#FCFAF6",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              fontSize: "3rem",
              paddingTop: "25px",
            }}
          >
            <span className="spec-h">shroom</span> dla B2B
          </h2>
          <div className="row" style={{ paddingTop: "50px" }}>
            <div className="col">
              {b2b1()}
              <h3
                className="b2bh"
                style={{
                  textAlign: "center",
                  color: "white",
                  marginTop: "-60px",
                  zIndex: "10",
                  position: "absolute",
                  width: "95%",
                  maxWidth: "500px",
                }}
              >
                <span className="spec-h">shroom</span> dla HoReCa
              </h3>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "25px",
                  marginBottom: "15px",
                }}
              >
                BAR RESTAURACJA HOTEL BISTRO
              </p>
              <p>
                Kierujesz swoją ofertę do klientów dbających o zdrowie, którzy
                dokunują świadomych wyborów? Znudziła Ci się kombucha?
                Skorzystaj z naszych specjalnych cen i pakietów oraz wsparcia
                sprzedażowego.
              </p>
            </div>
            <div className="col">
              {b2b2()}
              <h3
                className="b2bh"
                style={{
                  textAlign: "center",
                  color: "white",
                  marginTop: "-60px",
                  zIndex: "10",
                  position: "absolute",
                  width: "95%",
                  maxWidth: "500px",
                }}
              >
                <span className="spec-h">shroom</span> dla dystrybutorów
              </h3>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "25px",
                  marginBottom: "15px",
                }}
              >
                Zostań naszym partnerem
              </p>
              <p>
                Bez Ciebie nie dalibyśmy rady! Chcesz przybliżyć ludziom
                adaptogeny i wellness? Szukasz alternatyw dla alkoholu?
                Skontaktuj się z nami, aby zostać naszym partnerem
                dystrybucyjnym. Elastyczne zamówienia, materiały marketingowe i
                dedykowane wsparcie pomogą Ci w osiągnięciu sukcesu
                sprzedażowego!
              </p>
            </div>
            <div className="col">
              {b2b3()}
              <h3
                className="b2bh"
                style={{
                  textAlign: "center",
                  color: "white",
                  marginTop: "-60px",
                  zIndex: "10",
                  position: "absolute",
                  width: "95%",
                  maxWidth: "500px",
                }}
              >
                <span className="spec-h">shroom</span> dla biur
              </h3>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "25px",
                  marginBottom: "15px",
                }}
              >
                Dostarczone prosto do Ciebie!
              </p>
              <p>
                Dbasz o swoich pracowników do tego stopnia, że nie chcesz, aby
                spożywali niezdrowe jedzenie i napoje? Masz dość spadków energii
                po kofeinie i cukrze, które obniżają produktywność Twojego
                zespołu? Nie martw się!Shroom jest tutaj, by wspomóc
                produktywność i zadbać o siebie na najwyższym poziomie!
              </p>
            </div>
          </div>
          <br />
          <p style={{ textAlign: "center" }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://shop.shroom4you.com/pl/kontakt/"
              className="btn"
            >
              skontaktuj się&nbsp;
              <span role="img" aria-label="mushroom">
                🍄
              </span>
            </a>
          </p>
        </div>

        <div
          className="mt-5 container-fuild has-text-centered"
          style={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            margin: "1em 0em",
            padding: "2em 1em",
          }}
        >
          <h2 style={{ textAlign: "center" }} className="spec-h">
            Zamów shroom już dziś i poczuj różnicę!
          </h2>
          <p style={{ textAlign: "center" }}>
            Naturalne suplementy wspomagające funkcje poznawcze, wzmacniające
            odporność i redukujące stres.
          </p>
        </div>

        <SliderDrinks />
        <br />
        <br />
        <br />
        <br />

        <Revs />
        <br />
        <br />
        <BlogModule />
        <br />
        <p style={{ textAlign: "center" }}>
          <Link to="/pl/blog/" className="btn btn-outline-dark mush">
            zobacz blog&nbsp;
            <span role="img" aria-label="mushroom">
              🍄
            </span>
          </Link>
        </p>
        <br />
        <br />
      </Layout>
    </>
  );
};

export default Home;

export const pageQuery = graphql`
  query GetEnergyDrinksPL {
    allWpProduct(
      filter: {
        productCategories: {
          nodes: { elemMatch: { slug: { eq: "napoje-grzybowe-i-roslinne" } } }
        }
      }
    ) {
      edges {
        node {
          id
          name
          link
          image {
            id
            altText
            uri
            sizes
            sourceUrl
            srcSet
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: DOMINANT_COLOR
                  layout: FULL_WIDTH
                )
              }
            }
          }
          description
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: 7
      filter: { language: { code: { eq: PL } } }
    ) {
      edges {
        node {
          id
          date(formatString: "DD.MM.YYYY")
          link
          title
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          excerpt
          author {
            node {
              name
              avatar {
                url
              }
            }
          }
          categories {
            nodes {
              name
              uri
            }
          }
          tags {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`;
